// The Lift-and-Sift ID of the Portfolio domain.
export const PORTFOLIO_DOMAIN_ID = 'POMA_Portfolio';

// List of fields on the POMA_Portfolio domain to query when loading portfolio data
// This is an alternative to querying all fields from the domain, which has caused bugs when custom fields were misconfigured
export const PORTFOLIO_FIELDS_TO_QUERY = [
    'CreateValuationView',
    'EnableInvestment',
    'EnableValuationGroup',
    'Id',
    'IsActive',
    'LongName',
    'Name',
    'Portfolio_EnableInvestment',
    'Portfolio_EnableValuationGroup',
    'Portfolio_Type',
    'Portfolio_TypeCode',
    'PortfolioId',
    'PortfolioRegionCodes',
    'ShortName',
    'ValuationSetupAssetDefaultView',
    'ValuationSetupGroupDefaultView',
    'Portfolio_DisplayAssetValuationDifferenceCount',
    'Portfolio_IncludeDebtEquityAnalysis',
    'EnableBidEngage',
    'Portfolio_IncludeApproveProposedValuers',
    'Portfolio_IncludeApproveProposedValuersLevel2',
    'Portfolio_IncludeApproveRecommendationValuers',
    'Portfolio_IncludeApproveRecommendationValuersLevel2',
    'Portfolio_IncludeReviewProposalsInternally',
    'Portfolio_IncludeReviewProposalsExternally',
] as const;

// The field on the portfolio domain which represents a portfolio's name
// (Enforced via type annotation that this value exists in the list of queried fields above)
export const PORTFOLIO_FIELDNAME_NAME: (typeof PORTFOLIO_FIELDS_TO_QUERY)[number] = 'ShortName';

// This is the domain field name of the portfolio ID for domains which "include" portfolio within it, but don't
// necessarily have a direct relation to portfolio (e.g., Assets).
export const PORTFOLIO_FIELDNAME_ID_AS_INCLUDED = 'Portfolio_Id';

export const PORTFOLIO_PLURAL_FIELDNAME_ID_AS_INCLUDED = 'Portfolio_Ids';

export const OPTIONAL_PORTFOLIO_FIELDNAME = 'Optional_Portfolio_Ids';

// This is the domain field name of the portfolio ID for domains which directly relate to portfolios (e.g., Funds).
export const PORTFOLIO_FIELDNAME_ID_DIRECT_RELATION = 'PortfolioId';

// Match up with fields defined in:
// https://dev.azure.com/samcado/VMSNext/_git/Portfolio?path=/src/Portfolio.DataModels/Models/Entities/Portfolios/Portfolio.cs
export const PORTFOLIO_INCLUDED_FIELD_PREFIX = 'Portfolio_';

// Used externally at:
//  - https://samcado.visualstudio.com/VMSNext/_git/Screen-Config?path=/src/Web/packages/single-spa-screen-config/src/pages/ViewSetPage/ViewSetPage.tsx
export const URL_PARAMNAME_FILTER = 'filter';

// Used externally at:
//  - https://samcado.visualstudio.com/VMSNext/_git/Screen-Config?path=/src/Web/packages/single-spa-screen-config/src/pages/ViewSetPage/ViewSetPage.tsx
export const URL_PARAMNAME_DEFAULTS = 'defaults';
